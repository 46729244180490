<template>
  <main class="flex-1 p-8">
    <OSidebar
      variant="primary"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      :open.sync="open"
    >
      <OButton
        v-if="fullwidth"
        icon-left="times"
        label="Close"
        @click="open = false"
      />
      <img
        width="128"
        src="https://avatars2.githubusercontent.com/u/66300512?s=200&v=4"
        alt="Lightweight UI components for Vue.js"
      />
      <h3>Example</h3>
    </OSidebar>
    <div class="block">
      <OField grouped group-multiline>
        <OSwitch v-model="overlay">Overlay</OSwitch>
        <OSwitch v-model="fullheight">Fullheight</OSwitch>
        <OSwitch v-model="fullwidth">Fullwidth</OSwitch>
        <OSwitch v-model="right">Right</OSwitch>
      </OField>
    </div>

    <br />

    <OButton @click="open = true">Show</OButton>
  </main>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      overlay: true,
      fullheight: true,
      fullwidth: false,
      right: false,
    }
  },
}
</script>
